.loadingMises{
  text-align: center;
  font-size: 20px;
  color: #ccc;
  margin-top: 20px;
}
.loadingMises-refreshTxt{
  margin-top: 5px;
}
.UndoOutline{
  margin-top: 20px;
  font-size: 30px;
  color: var(--icon-color)
}
@primary-color: #1DA57A;