*, ::before, ::after{
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness:proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop{
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness:proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop{
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness:proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible{
  visibility:visible;
}

.absolute{
  position:absolute;
}

.relative{
  position:relative;
}

.right-3{
  right:12px;
}

.my-8{
  margin-top:8px;
  margin-bottom:8px;
}

.mb-10{
  margin-bottom:10px;
}

.mb-20{
  margin-bottom:20px;
}

.mb-40{
  margin-bottom:40px;
}

.mb-5{
  margin-bottom:5px;
}

.ml-10{
  margin-left:10px;
}

.ml-4{
  margin-left:4px;
}

.ml-5{
  margin-left:5px;
}

.mr-10{
  margin-right:10px;
}

.mt-10{
  margin-top:10px;
}

.mt-20{
  margin-top:20px;
}

.block{
  display:block;
}

.flex{
  display:flex;
}

.h-14{
  height:14px;
}

.h-screen{
  height:100vh;
}

.w-auto{
  width:auto;
}

.flex-1{
  flex:1 1 0%;
}

.flex-none{
  flex:none;
}

.flex-grow{
  flex-grow:1;
}

.cursor-pointer{
  cursor:pointer;
}

.list-outside{
  list-style-position:outside;
}

.list-decimal{
  list-style-type:decimal;
}

.flex-col{
  flex-direction:column;
}

.items-center{
  align-items:center;
}

.justify-center{
  justify-content:center;
}

.justify-between{
  justify-content:space-between;
}

.gap-1{
  gap:4px;
}

.gap-2{
  gap:8px;
}

.gap-4{
  gap:16px;
}

.overflow-auto{
  overflow:auto;
}

.overflow-hidden{
  overflow:hidden;
}

.truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.rounded{
  border-radius:4px;
}

.p-10{
  padding:10px;
}

.p-16{
  padding:16px;
}

.p-20{
  padding:20px;
}

.px-10{
  padding-left:10px;
  padding-right:10px;
}

.px-12{
  padding-left:12px;
  padding-right:12px;
}

.px-16{
  padding-left:16px;
  padding-right:16px;
}

.py-10{
  padding-top:10px;
  padding-bottom:10px;
}

.py-15{
  padding-top:15px;
  padding-bottom:15px;
}

.py-20{
  padding-top:20px;
  padding-bottom:20px;
}

.py-40{
  padding-top:40px;
  padding-bottom:40px;
}

.pr-12{
  padding-right:12px;
}

.pt-20{
  padding-top:20px;
}

.text-left{
  text-align:left;
}

.text-center{
  text-align:center;
}

.text-right{
  text-align:right;
}

.text-24{
  font-size:24px;
}

.text-2xl{
  font-size:20px;
  line-height:22px;
}

.text-\[16px\]{
  font-size:16px;
}

.text-lg{
  font-size:16px;
  line-height:18px;
}

.text-sm{
  font-size:12px;
  line-height:14px;
}

.text-xl{
  font-size:18px;
  line-height:20px;
}

.font-bold{
  font-weight:700;
}

.leading-6{
  line-height:24px;
}

.text-blue-600{
  --tw-text-opacity:1;
  color:rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-500{
  --tw-text-opacity:1;
  color:rgb(107 114 128 / var(--tw-text-opacity));
}

.shadow-lg{
  --tw-shadow:0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored:0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter{
  -webkit-filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

html{
  font-size:16px;
}

@media (prefers-color-scheme: dark) {
  :root:root{
    --border-color: rgba(152, 161, 192, 0.24);
    --widget-bg-color: rgb(41, 50, 73);
    --theme-text-color: white;
    --background-color: #000000;
    --icon-color: rgb(152, 161, 192)
    
  }

  .dialog-container .adm-center-popup-wrap >div{
    border: 1px solid rgba(152, 161, 192, 0.24);
    border-radius: 12px;
    overflow: hidden;
  }
}

@media (prefers-color-scheme: light) {
  :root:root{
    --border-color:rgb(210, 217, 238);
    --widget-bg-color: rgb(232, 236, 251);
    --theme-text-color: #0d111c;
    --background-color: #ffffff;
    --icon-color: #7780a0
    
  }

  .dialog-container:not(.down-dialog-style) .adm-center-popup-wrap >div{
    border: 1px solid rgb(210, 217, 238);
    border-radius: 12px;
    overflow: hidden;
  }
}

body{
  margin:0;
  padding:0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  background:var(--background-color);
}

:root:root{
  --adm-color-primary: #5D61FF;
  --fixed-active-line-width: 9px;
  --border-radius: 12px

  
}

div{
  line-height:1
}

p{
  margin: 0;
  padding: 0;
}

.color-primary{
  color:var(--theme-color)
}

.adm-tabs-tab.adm-tabs-tab-active{
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

iframe{
  border: none !important;
}

body,html,#root,.App{
  width: 100%;
  height: 100%;
}

.App >div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.swap-title{
  color: var(--adm-color-primary);
  font-size: 24px;
  font-weight: bold;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

@media screen and (max-width:767px){
  .dialog-container:not(.down-dialog-style){
    --min-width: 90vw;
  }

  /* .mises-title{
    display: none;
  } */

  .swap-title{
    font-size: 18px;
  }

  .down-dialog-style{
    --min-width: 100vw;
  }

  .down-dialog-style .adm-center-popup-body{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .down-dialog-style .adm-center-popup-wrap{
    top: unset;
    bottom: 0;
    -webkit-transform: unset;
            transform: unset;
    left: 0;
    z-index: 1000;
  }
}

@media screen and (min-width:768px){
  .dialog-container{
    --min-width: 408px;
    --max-width: 408px;
  }
}

[data-rk] .ju367v81 {
  padding-top: 2px;
  max-height: 60vh;
  overflow: auto;
}

.after\:content-\[\'_\2197\'\]::after{
  --tw-content:' ↗';
  content:var(--tw-content);
}
